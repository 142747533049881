import React from 'react';
import FilterCheckbox from './FilterCheckbox';
import { FILTERS } from '../constants';
import { Flex, Label } from 'theme-ui';
import { useTranslation } from 'react-i18next';

const FilterInputs = ({
  label,
  filterOptions,
  activeFilters,
  onFilter,
}: {
  label: string;
  filterOptions: {
    label: string;
    value: string;
  }[];
  activeFilters: string[];
  onFilter: (selectedFilterValue: string) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        mb: 4,
      }}
    >
      <Label
        sx={{
          mb: 2,
        }}
      >
        <span>{t(label)}</span>
      </Label>
      {filterOptions.map(filter => {
        const isChecked =
          filter.value === FILTERS.ALL.value
            ? activeFilters.length === 0
            : activeFilters.includes(filter.value);
        return (
          <FilterCheckbox
            key={filter.value}
            isChecked={isChecked}
            label={filter.label}
            onToggle={onFilter}
            value={filter.value}
          />
        );
      })}
    </Flex>
  );
};

export default FilterInputs;
