import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { ThemeUIStyleObject } from 'theme-ui';

export const MODAL_STYLES = {
  overlay: {
    inset: '0',
    display: 'fixed',
    background: 'transparent',
    pointerEvents: 'none',
  },
  content: {
    inset: 'initial',
    border: 'none',
    padding: 0,
    height: '100%',
    position: 'relative',
    background: 'transparent',
    pointerEvents: 'none',
  },
};
export const MODAL_CONTAINER_STYLES: ThemeUIStyleObject = {
  position: 'absolute',
  top: ['66px', '96px'],
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  pointerEvents: 'all',
};
export const MODAL_HEADER_CONTAINER_STYLES: ThemeUIStyleObject = {
  gridTemplateColumns: '24px 1fr 24px',
  px: ['16px', '80px'],
  borderBlock: '1px solid #EEEEEE',
};
export const MODAL_HEADER_TITLE_STYLES: ThemeUIStyleObject = {
  gridColumn: 2,
  mb: 0,
  justifySelf: 'center',
  py: '16px',
};
export const MODAL_HEADER_CLOSE_BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  width: '24px',
  height: ['44px', null, null, '24px'],
  alignSelf: 'center',
  gridColumn: 3,
};
export const MODAL_HEADER_CLOSE_BUTTON_ICON_STYLES: ThemeUIStyleObject = {
  height: '12px',
  width: '12px',
  fill: 'darkGrey',
};
