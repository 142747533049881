/** @jsx jsx */
import { useCallback } from 'react';
import { ThemeUIStyleObject, jsx } from 'theme-ui';
import Check from '@sprinklr/shared-lib/assets/images/check.png';
import { useTranslation } from 'react-i18next';

const INPUT_STYLES: ThemeUIStyleObject = {
  margin: 0,
  marginRight: '12px',
  width: '20px',
  height: '20px',
  appearance: 'none',
  backgroundColor: 'formFieldGrey',
  borderRadius: '4px',
  position: 'relative',
  top: '5px',
  border: 0,
  '&:focus-visible': {
    outlineColor: 'primary',
  },
  ':checked': {
    backgroundColor: 'secondary',
    backgroundImage: `url(${Check})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12px 9px',
    backgroundPosition: 'center',
    backgroundClip: 'border-box',
  },
};

const LABEL_STYLES: ThemeUIStyleObject = {
  mb: 1,
  fontWeight: 'semiBold',
  fontSize: 1,
  paddingLeft: '32px',
  textIndent: '-32px',
};

const FilterCheckbox = ({
  isChecked,
  label,
  value,
  onToggle,
}: {
  isChecked: boolean;
  label: string;
  value: string;
  onToggle: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const handleChange = useCallback(() => onToggle(value), [onToggle, value]);
  return (
    <label sx={LABEL_STYLES}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        sx={INPUT_STYLES}
      />
      {t(label)}
    </label>
  );
};

export default FilterCheckbox;
