/** @jsx jsx */
import React from 'react';
import { ThemeUIStyleObject, jsx } from 'theme-ui';

const FilterForm = ({
  onSubmit,
  children,
  formSx = {},
}: {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  formSx?: ThemeUIStyleObject;
  children?: React.ReactNode;
}) => {
  return (
    <form sx={formSx} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default FilterForm;
