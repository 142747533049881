/** @jsx jsx */
import React, { useCallback, useState } from 'react';
import { jsx, Box, Button, Flex, Themed, Grid } from 'theme-ui';
import FilterModal from '../../components/FilterModal';
import { useTranslation } from 'react-i18next';
import FilterForm from '../../components/FilterForm';
import SortDropdown from '../../components/SortDropdown';
import FilterInputs from '../../components/FilterInputs';
import { ApplyButton } from '../../components/ApplyButton';
import { ClearButton } from '../../components/ClearButton';
import {
  MOBILE_CONTAINER_STYLES,
  FILTER_MODAL_CONTROLS_CONTAINER_STYLES,
  FILTER_MODAL_CONTROLS_BUTTON_CONTAINER_STYLES,
  FILTER_SORT_BUTTON_STYLES,
  REMOVE_FILTERS_BUTTON_STYLES,
  FILTER_INSIGHT_TEXT_STYLES,
  FILTER_FORM_STYLES,
  FILTER_FORM_INPUT_CONTAINER_STYLES,
  FILTER_FORM_BUTTON_CONTAINER_STYLES,
} from './styles';

import { LayoutProps } from '../../types';
import { FILTER_CATEGORIES_LABEL } from '../../constants';

const FILTER_SORT_BUTTON_LABEL = 'Filter & Sort';
const REMOVE_FILTER_BUTTON_LABEL = 'Remove Filter';

const MobileLayout = (props: LayoutProps) => {
  const {
    onRemove,
    activeSortType,
    filteredPostCount,
    sortOptions,
    onApply,
    activeFilters,
    filterOptions,
    onClear,
    onFilter,
    onSort,
  } = props;
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const { t } = useTranslation();
  const activeSortTypeLabel =
    sortOptions.find(option => option.value === activeSortType)?.label ??
    activeSortType;

  const filterInsightText = filteredPostCount
    ? `${filteredPostCount} ${t('topics found, sorted by')} ${t(
        activeSortTypeLabel,
      )}.`
    : null;

  const openModal = useCallback(() => setIsFilterModalOpen(true), []);

  const handleModalClose = useCallback(() => setIsFilterModalOpen(false), []);

  const handleFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      setIsFilterModalOpen(false);
      onApply(e);
    },
    [onApply],
  );
  return (
    <Box sx={MOBILE_CONTAINER_STYLES}>
      <Flex sx={FILTER_MODAL_CONTROLS_CONTAINER_STYLES}>
        <Flex sx={FILTER_MODAL_CONTROLS_BUTTON_CONTAINER_STYLES}>
          <Button
            type="button"
            onClick={openModal}
            sx={FILTER_SORT_BUTTON_STYLES}
          >
            {t(FILTER_SORT_BUTTON_LABEL)}
          </Button>
          <Button
            type="button"
            onClick={onRemove}
            sx={REMOVE_FILTERS_BUTTON_STYLES}
          >
            {t(REMOVE_FILTER_BUTTON_LABEL)}
          </Button>
        </Flex>
        {filterInsightText ? (
          <Themed.p sx={FILTER_INSIGHT_TEXT_STYLES}>
            {filterInsightText}
          </Themed.p>
        ) : null}
      </Flex>
      <FilterModal isOpen={isFilterModalOpen} onClose={handleModalClose}>
        <FilterForm formSx={FILTER_FORM_STYLES} onSubmit={handleFormSubmit}>
          <Box sx={FILTER_FORM_INPUT_CONTAINER_STYLES}>
            <SortDropdown
              sortOptions={sortOptions}
              sortType={activeSortType}
              onSort={onSort}
            />
            <FilterInputs
              label={FILTER_CATEGORIES_LABEL}
              filterOptions={filterOptions}
              activeFilters={activeFilters}
              onFilter={onFilter}
            />
          </Box>
          <Grid sx={FILTER_FORM_BUTTON_CONTAINER_STYLES}>
            <ApplyButton />
            <ClearButton onClear={onClear} />
          </Grid>
        </FilterForm>
      </FilterModal>
    </Box>
  );
};

export default MobileLayout;
