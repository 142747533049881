export const FILTERS = {
  CXM: { label: 'CXM', value: 'cxm' },
  SERVICE: { label: 'Customer Service', value: 'service' },
  MARKETING: { label: 'Marketing & Advertising', value: 'marketing' },
  INSIGHTS: { label: 'Research & Insights', value: 'insights' },
  SOCIAL: { label: 'Social Media Management', value: 'social' },
  ALL: { label: 'All', value: 'all' },
};

export const SORTS = {
  NEWEST: { value: 'newest' as const, label: 'Newest' },
  OLDEST: { value: 'oldest' as const, label: 'Oldest' },
  ALPHA: { value: 'alpha' as const, label: 'A-Z' },
};

export const FILTER_OPTIONS = Object.values(FILTERS);
export const SORT_OPTIONS = Object.values(SORTS);

export const FILTER_CATEGORY_SEARCH_PARAM = 'category';
export const FILTER_VALUE_SEPARATOR = '+';
export const SORT_BY_SEARCH_PARAM = 'sort';

export const CXM_TOPICS_SLUG = '/cxm/topics/';

export const DEFAULT_SORT_VALUE = SORTS.NEWEST.value;

export const FILTER_CATEGORIES_LABEL = 'Category';
