import { navigate } from 'gatsby';
import { ContentHubPost } from '../../types';
import {
  FILTERS,
  FILTER_CATEGORY_SEARCH_PARAM,
  SORT_BY_SEARCH_PARAM,
  FILTER_VALUE_SEPARATOR,
  CXM_TOPICS_SLUG,
  SORTS,
  DEFAULT_SORT_VALUE,
} from './constants';

const getSortCompareFunction = (sortType: string) => {
  switch (sortType) {
    case SORTS.ALPHA.value: {
      return (a: ContentHubPost, b: ContentHubPost) =>
        a?.slug.localeCompare(b?.slug);
    }
    case SORTS.OLDEST.value: {
      return (a: ContentHubPost, b: ContentHubPost) => {
        const aDate = !!a.sortingDate ? Date.parse(a.sortingDate) : 0;
        const bDate = !!b.sortingDate ? Date.parse(b.sortingDate) : 0;
        return aDate - bDate;
      };
    }
    case SORTS.NEWEST.value: {
      return (a: ContentHubPost, b: ContentHubPost) => {
        const aDate = !!a.sortingDate ? Date.parse(a.sortingDate) : 0;
        const bDate = !!b.sortingDate ? Date.parse(b.sortingDate) : 0;
        return bDate - aDate;
      };
    }
  }
};

export const getFilteredPosts = (
  posts: ContentHubPost[],
  filterValues: string[],
  sortType: string,
) => {
  const appliedFilterLabels = filterValues.map(
    appliedFilterValue =>
      Object.values(FILTERS).find(filter => filter.value === appliedFilterValue)
        ?.label,
  );
  const filteredPosts = posts
    .filter(post => {
      if (appliedFilterLabels.length <= 0) {
        return true;
      }
      return appliedFilterLabels.includes(post.contentHubPostCategory);
    })
    .sort(getSortCompareFunction(sortType));
  return filteredPosts;
};

export const setFilterQueryParams = (
  appliedFilters: string[],
  sortType: string,
) => {
  const url = new URL(window.location.toString());
  url.pathname = CXM_TOPICS_SLUG;
  if (appliedFilters.length > 0) {
    url.searchParams.set(
      FILTER_CATEGORY_SEARCH_PARAM,
      appliedFilters.join(FILTER_VALUE_SEPARATOR),
    );
  } else {
    url.searchParams.delete(FILTER_CATEGORY_SEARCH_PARAM);
  }
  url.searchParams.set(SORT_BY_SEARCH_PARAM, sortType);
  window.history.pushState({}, '', url.pathname + url.search);
  navigate(url.pathname + url.search);
};

export const getFilterValueFromSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const filterValuesFromSearchParams =
    searchParams
      .get(FILTER_CATEGORY_SEARCH_PARAM)
      ?.split(FILTER_VALUE_SEPARATOR) ?? [];
  const sortTypeFromSearchParams =
    searchParams.get(SORT_BY_SEARCH_PARAM) ?? DEFAULT_SORT_VALUE;
  return { filterValuesFromSearchParams, sortTypeFromSearchParams };
};
