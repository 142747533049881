import React from 'react';
import Dropdown from '@sprinklr/shared-lib/components/Dropdown';
import { ThemeUIStyleObject } from 'theme-ui';

export const SORT_DROPDOWN_STYLES: ThemeUIStyleObject = {
  maxWidth: [null, '50%', null, '100%'],
};

const SortDropdown = ({
  sortOptions,
  sortType,
  onSort,
}: {
  sortOptions: {
    label: string;
    value: string;
  }[];
  sortType: string;
  onSort: (value: string) => void;
}) => {
  return (
    <Dropdown
      value={sortType}
      setValue={onSort}
      options={sortOptions}
      dropdownSx={SORT_DROPDOWN_STYLES}
    />
  );
};

export default SortDropdown;
