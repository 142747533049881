import {
  buttonReset,
  sharedButtonStyles,
} from '@sprinklr/shared-lib/components/button/styles';
import { ThemeUIStyleObject } from 'theme-ui';

export const MOBILE_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: ['block', null, null, 'none'],
};
export const FILTER_MODAL_CONTROLS_CONTAINER_STYLES: ThemeUIStyleObject = {
  flexDirection: ['column', 'row'],
  justifyContent: 'space-between',
  alignItems: ['flex-start', 'center'],
  gap: '16px',
};
export const FILTER_MODAL_CONTROLS_BUTTON_CONTAINER_STYLES: ThemeUIStyleObject =
  {
    gap: '12px',
  };
export const FILTER_SORT_BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  ...sharedButtonStyles,
  variant: 'buttons.primarySmall',
};
export const REMOVE_FILTERS_BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  ...sharedButtonStyles,
  variant: 'buttons.secondarySmall',
};
export const FILTER_FORM_STYLES: ThemeUIStyleObject = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
};
export const FILTER_FORM_BUTTON_CONTAINER_STYLES: ThemeUIStyleObject = {
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
  borderTop: '1px solid rgb(238, 238, 238)',
  paddingBlock: 3,
  paddingInline: [3, '80px'],
};
export const FILTER_INSIGHT_TEXT_STYLES: ThemeUIStyleObject = {
  fontWeight: 'semiBold',
  color: 'mediumGrey',
  fontSize: [1, 1, 1],
  mb: [0],
};
export const FILTER_FORM_INPUT_CONTAINER_STYLES: ThemeUIStyleObject = {
  paddingBlockStart: 3,
  paddingInline: [3, '80px'],
  flex: 1,
};
