import React from 'react';
import { Button } from 'theme-ui';
import {
  buttonReset,
  sharedButtonStyles,
} from '@sprinklr/shared-lib/components/button/styles';
import { useTranslation } from 'react-i18next';

export const ClearButton = ({ onClear }) => {
  const { t } = useTranslation();
  return (
    <Button
      type="button"
      onClick={onClear}
      sx={{
        ...buttonReset,
        ...sharedButtonStyles,
        width: '100%',
        variant: 'buttons.secondarySmall',
      }}
    >
      {t('Clear all filters')}
    </Button>
  );
};
