import React from 'react';
import FilterForm from '../../components/FilterForm';
import { FILTERS, FILTER_CATEGORIES_LABEL } from '../../constants';
import { Box, Grid } from 'theme-ui';
import FilterInputs from '../../components/FilterInputs';
import { ApplyButton } from '../../components/ApplyButton';
import { ClearButton } from '../../components/ClearButton';
import SortDropdown from '../../components/SortDropdown';
import { LayoutProps } from '../../types';
import { DESKTOP_CONTAINER_STYLES, BUTTON_GRID_STYLES } from './styles';

const DesktopLayout = (props: LayoutProps) => {
  const {
    onApply,
    activeSortType,
    onSort,
    activeFilters,
    onFilter,
    onClear,
    sortOptions,
    filterOptions,
  } = props;
  return (
    <Box sx={DESKTOP_CONTAINER_STYLES}>
      <FilterForm onSubmit={onApply}>
        <SortDropdown
          sortOptions={sortOptions}
          sortType={activeSortType}
          onSort={onSort}
        />
        <FilterInputs
          label={FILTER_CATEGORIES_LABEL}
          filterOptions={filterOptions}
          activeFilters={activeFilters}
          onFilter={onFilter}
        />
        <Grid sx={BUTTON_GRID_STYLES}>
          <ApplyButton />
          <ClearButton onClear={onClear} />
        </Grid>
      </FilterForm>
    </Box>
  );
};
export default DesktopLayout;
