import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { ContentHubFilterFormProps } from '../../types';
import MobileLayout from './layouts/MobileLayout/MobileLayout';
import DesktopLayout from './layouts/DesktopLayout';
import {
  DEFAULT_SORT_VALUE,
  FILTERS,
  FILTER_OPTIONS,
  SORT_OPTIONS,
} from './constants';
import {
  getFilterValueFromSearchParams,
  getFilteredPosts,
  setFilterQueryParams,
} from './utils';

const ContentHubFilterForm: React.FC<ContentHubFilterFormProps> = props => {
  const { postsArray, filteredPostCount, setSortedPosts } = props;

  const [sortType, setSortType] = useState<string>(DEFAULT_SORT_VALUE);
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad) {
      const { filterValuesFromSearchParams, sortTypeFromSearchParams } =
        getFilterValueFromSearchParams();

      setAppliedFilters(filterValuesFromSearchParams);
      setSortType(sortTypeFromSearchParams);

      setSortedPosts(
        getFilteredPosts(
          postsArray,
          filterValuesFromSearchParams,
          sortTypeFromSearchParams,
        ),
      );

      setInitialLoad(false);
    }
  }, []);

  const handleFilter = useCallback((selectedFilterValue: string) => {
    if (selectedFilterValue === FILTERS.ALL.value) {
      setAppliedFilters([]);
    } else {
      setAppliedFilters(currentAppliedFilters => {
        if (currentAppliedFilters.includes(selectedFilterValue))
          return currentAppliedFilters.filter(
            filterValue => filterValue !== selectedFilterValue,
          );
        return [...currentAppliedFilters, selectedFilterValue];
      });
    }
  }, []);

  const handleApply = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setFilterQueryParams(appliedFilters, sortType);
      setSortedPosts(getFilteredPosts(postsArray, appliedFilters, sortType));
    },
    [postsArray, appliedFilters, sortType],
  );

  const handleClear = useCallback(() => {
    setSortType(DEFAULT_SORT_VALUE);
    setAppliedFilters([]);
  }, []);

  const handleRemoveFilter = () => {
    setFilterQueryParams([], DEFAULT_SORT_VALUE);
    setAppliedFilters([]);
    setSortType(DEFAULT_SORT_VALUE);
    setSortedPosts(getFilteredPosts(postsArray, [], DEFAULT_SORT_VALUE));
  };

  return (
    <Fragment>
      <DesktopLayout
        filteredPostCount={filteredPostCount}
        filterOptions={FILTER_OPTIONS}
        activeFilters={appliedFilters}
        onFilter={handleFilter}
        sortOptions={SORT_OPTIONS}
        activeSortType={sortType}
        onSort={setSortType}
        onApply={handleApply}
        onClear={handleClear}
        onRemove={handleRemoveFilter}
      />
      <MobileLayout
        filteredPostCount={filteredPostCount}
        filterOptions={FILTER_OPTIONS}
        activeFilters={appliedFilters}
        onFilter={handleFilter}
        sortOptions={SORT_OPTIONS}
        activeSortType={sortType}
        onSort={setSortType}
        onApply={handleApply}
        onClear={handleClear}
        onRemove={handleRemoveFilter}
      />
    </Fragment>
  );
};

export default ContentHubFilterForm;
