import React from 'react';
import { Button } from 'theme-ui';
import {
  buttonReset,
  sharedButtonStyles,
} from '@sprinklr/shared-lib/components/button/styles';
import { useTranslation } from 'react-i18next';

export const ApplyButton = () => {
  const { t } = useTranslation();
  return (
    <Button
      type="submit"
      sx={{
        ...buttonReset,
        ...sharedButtonStyles,
        width: '100%',
        variant: 'buttons.primarySmall',
        ':disabled': {
          backgroundColor: 'disabled',
          cursor: 'default',
        },
      }}
    >
      {t('Apply Filter')}
    </Button>
  );
};
