/** @jsx jsx */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { jsx, Box, Themed, Grid } from 'theme-ui';
import ReactModal from 'react-modal';
import CloseIcon from '@sprinklr/shared-lib/assets/svgs/close.svg';
import {
  MODAL_STYLES,
  MODAL_CONTAINER_STYLES,
  MODAL_HEADER_CONTAINER_STYLES,
  MODAL_HEADER_TITLE_STYLES,
  MODAL_HEADER_CLOSE_BUTTON_STYLES,
  MODAL_HEADER_CLOSE_BUTTON_ICON_STYLES,
} from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const FILTER_SORT_LABEL = 'Filter & Sort';

const FilterModal = (props: Props) => {
  const { isOpen, onClose, children } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <ReactModal isOpen={isOpen} onRequestClose={onClose} style={MODAL_STYLES}>
        <Box sx={MODAL_CONTAINER_STYLES}>
          <Grid sx={MODAL_HEADER_CONTAINER_STYLES}>
            <Themed.h6 sx={MODAL_HEADER_TITLE_STYLES}>
              {`${t(FILTER_SORT_LABEL)}`}
            </Themed.h6>
            <button onClick={onClose} sx={MODAL_HEADER_CLOSE_BUTTON_STYLES}>
              <CloseIcon sx={MODAL_HEADER_CLOSE_BUTTON_ICON_STYLES} />
            </button>
          </Grid>
          {children}
        </Box>
      </ReactModal>
    </Fragment>
  );
};

export default FilterModal;
